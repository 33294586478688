.experience-container {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    background-color: #f3d0aeb5;
    height: 300vh;
  }

.content-cards {
    padding-left: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10%;
}

.company-logo {
    padding-left: 10%;
    padding-top: 3%;
    width: 70%;
    height: auto;
}