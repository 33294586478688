.App {
  text-align: center;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: #f3d0aeb5;
  overflow: hidden;

  button {
    font-family: 'Comic Sans MS', cursive;
    background-color: transparent;
    border: none;
  }

  button:hover {
    transform: scale(1.1);
  }

  .arrow {
    width: 5%;
    height: auto;
  }
}

.App-header {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(0, 0, 0);
  padding-top: 10%;
}

.Sub-header {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: calc(25px + 2vmin);
  color: rgb(87, 87, 87);
}

.instructions {
  margin-top: 0;
  margin-bottom: 5%;
  font-size: calc(25px + 2vmin);
}

.Title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: calc(50px + 2vmin);
}

.arrow {
  margin-bottom: 5%;
}

.controller{
  position: absolute;
  top: 0;
  left: 0;
  img {
    padding: 0;
    margin: 0;
  }
}