.about-container {
    background-color: #f3d0aeb5;
    height: 150vh;
  }

.content-cards {
    padding-left: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

