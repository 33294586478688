.content-container {
    padding-top: 3%;
    padding-left: 10%;
    width: 70%;
    font-family: 'Comic Sans MS', cursive, sans-serif;
}

.title {
    font-size: calc(30px + 2vmin);
    padding-bottom: 2%;
}

.content {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 150%;
}