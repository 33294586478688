.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 150px;
}

.about {
  padding-right: 250px;
}

.experience {
  padding-left: 250px;
}